
import {defineComponent, reactive, ref, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Client, UaProject} from "@/store/modules/ApiModule";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-client-modal",
  components: {},
  props: {
    client: {
      type: Object as () => Client,
      required: true
    }
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addClientModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = reactive<Client>({
      id: 0, name: ""
    });
    const store = useStore();

    watch(props.client, (newValue, oldValue) => {
      Object.assign(formData, newValue);
      if(formRef.value){
        formRef.value.clearValidate();
      }
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Client name is required",
          trigger: "change",
        },
      ]
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          //loading.value = true;
          ApiService.setHeader();
          return ApiService.post("clients", formData)
              .then(() => {
                store.dispatch(Actions.LOAD_CLIENTS);
                Swal.fire({
                  text: "Client has been saved!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  if(formRef.value){
                    formRef.value.resetFields();
                  }
                  hideModal(addClientModalRef.value);
                });
              })
              .catch((error) => {
                Swal.fire({
                  text: error.response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addClientModalRef,
    };
  },
});
