
import {defineComponent, ref, reactive, computed, onMounted, watch} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddClientModal from "@/components/modals/forms/AddClientModal.vue";
import DeleteModal from "@/components/modals/forms/DeleteModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import {Client} from "@/store/modules/ApiModule";

export default defineComponent({

  name: "clients-listing",
  components: {
    Datatable,
    AddClientModal,
    DeleteModal
  },

  setup() {
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const store = useStore();
    let myClients = ref<Array<Client>>([]);
    let selectedItem = reactive({} as Client);

    let myData = computed(() => {
      return store.state.api.clients;
    });

    const setSelectedItem = (item) => {
      if(item == null){
        Object.assign(selectedItem, {
          id: 0, name: ""
        });
      }
      else {
        Object.assign(selectedItem, item);
      }
    }

    onMounted(() => {
      MenuComponent.reinitialization();
      store.dispatch(Actions.LOAD_CLIENTS);
    });

    watch(myData, (newValue, oldValue) => {
      myClients.value.splice(0,myClients.value.length)
      newValue.forEach(function (value) {
        myClients.value.push(value);
      });
    });

    const search = ref<string>("");
    const searchItems = () => {
      /*tableData.value.splice(0, tableData.value.length, ...initClients.value);
      if (search.value !== "") {
        let results: Array<Client> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }*/
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      myData,
      myClients,
      selectedItem,
      setSelectedItem,
      tableHeader,
      search,
      searchItems
    };
  },
});
